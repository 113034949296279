<template>
  <div style="font-size:64px;margin:100px">很抱歉,您无权访问此页面</div>
</template>

<script>
export default {

}
</script>

<style>

</style>